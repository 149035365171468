import { faCampground, faHotel } from "@fortawesome/pro-duotone-svg-icons"
// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import admissionsLodgingStyle from "assets/jss/material-kit-pro-react/views/admissionsPageSections/admissionsLodgingStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles(admissionsLodgingStyle)

export default function PastWinnersDocuments() {
  const { visitEdgertonLogo } = useStaticQuery(graphql`
    query getAdmissionsLodgingImages {
      visitEdgertonLogo: file(
        relativePath: { eq: "logos/visit-edgerton-logo.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section} id="admissionsLodging">
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Lodging</h2>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <FontAwesomeIcon className={classes.documentIcons} icon={faHotel} />
          <h4 className={classes.subtitle}>Recommended Lodging</h4>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Quality Inn</b>
            </h5>
            <p className={classes.description}>
              11102 N. Goede Road <br />
              Edgerton, WI 53534 <br /> 608-884-2118
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Coachman’s Golf Resort</b>
            </h5>
            <p className={classes.description}>
              984 County Road A<br />
              Edgerton, WI 53534
              <br />
              608-884-8484
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Tru by Hilton</b>
            </h5>

            <p className={classes.description}>
              2500 Jackson Street
              <br />
              Stoughton, WI 53589
              <br />
              608-205-2566
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <FontAwesomeIcon
            className={classes.documentIcons}
            icon={faCampground}
          />
          <h4 className={classes.subtitle}>Campgrounds</h4>
          <br />
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Milton KOA</b>
            </h5>
            <p className={classes.description}>
              872 E. State Road 59 <br /> Milton, WI 53563 <br /> 680-868-4141
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Hickory Hills</b>
            </h5>
            <p className={classes.description}>
              856 Hillside Road <br /> Edgerton, WI 53534 <br /> 608-884-6327
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Blackhawk</b>
            </h5>
            <p className={classes.description}>
              3407 E. Blackhawk Drive <br /> Milton, WI 53563 <br />{" "}
              877-570-2267
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Lakeview</b>
            </h5>
            <p className={classes.description}>
              1901 E. State Road 59 <br /> Milton, WI 53563 <br /> 608-868-7800
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Lakeland</b>
            </h5>
            <p className={classes.description}>
              2803 E. State Road 59 <br /> Milton, WI 53563 <br /> 877-570-2267
            </p>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.documentsItem}>
            <h5 className={classes.description}>
              <b>Jellystone</b>
            </h5>
            <p className={classes.description}>
              N551 Wishing Well Lane <br /> Fort Atkinson, WI 53538 <br />{" "}
              920-568-4100
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <GatsbyImage
              image={visitEdgertonLogo.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              className={classes.contestsImage}
              alt="Visit Edgerton Logo"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
