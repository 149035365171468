import {
  container,
  description,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const admissionsLodgingStyle = (theme) => ({
  container,
  contestsImage: {
    maxHeight: "300px",
  },
  description,
  documentsButton: {
    margin: "0 auto",
  },
  documentIcons: {
    margin: "20px auto 0 auto",
    fontSize: "1.5rem",
  },
  documentsItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    margin: "1rem 0rem",
  },
  section: {
    ...section,
    padding: "80px 0px 0px 0px",
    background: "#fff",
  },
  subtitle: {
    ...title,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "0px",
    width: "100%",
  },
  title: {
    ...title,
    textAlign: "center",
    marginTop: "0",
  },
  pastWinnerInfo: {
    ...textCenter,
  },
})

export default admissionsLodgingStyle
